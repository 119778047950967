import { useQuery } from "react-query"
import { getCurrentWeatherData, getCurrentWeatherGraph, getWeatherData, getWeatherForecastData } from "./weatherDataUrls"

const weatherData = (query) => {
  return useQuery(["weatherData", query], () => getWeatherData(query), {
    refetchOnWindowFocus: false,
  })
}

const weatherForecastData = (query) => {
  return useQuery(["weatherForecastData", query], () => getWeatherForecastData(query), {
    refetchOnWindowFocus: false,
  })
}

const currentWeatherData = (query) => {
  return useQuery(["currentWeatherData", query], () => getCurrentWeatherData(query), {
    refetchOnWindowFocus: false,
  })
}

const currentWeatherGraph = (query) => {
  return useQuery(["currentWeatherGraph", query], () => getCurrentWeatherGraph(query), {
    refetchOnWindowFocus: false,
  })
}

const useWeatherDataQuery = {
  weatherData,
  currentWeatherData,
  weatherForecastData,
  currentWeatherGraph
};

export default useWeatherDataQuery
