import DataService from "../DataService"
const API_URL = 'https://indraawsapi.misteo.co/weather/api/v1/forecast';
const AUTH_TOKEN = 'Bearer f7AmejFHa302cUn3aczf7vVlMhzDMyPZu4N9Vl1woHlwdAbBSySCVPFpgtBWbrjAXLxFbBSXtQmZNxfAUgapqOkHO6GjFkdmi6IbCHitibM6RRyVw6WwWJ7UCDAPyDEV';

const getWeatherData = async ({ limit, offset, deviceId }) => {
  let url = `data/list/?limit=${limit}&offset=${offset}&device_id=${deviceId}`
  const data = await DataService.get(url)
  return data.data
};

const exportWeatherDataCSV = async ({ limit, offset, deviceId, startDate, endDate }) => {
  let url = `csv/?limit=${limit}&offset=${offset}&start_date=${startDate}&end_date=${endDate}&device_id=${deviceId}`
  const data = await DataService.get(url)
  return data
};

const exportWeatherDataPdf = async ({ limit, offset, deviceId, startDate, endDate }) => {
  let url = `pdf/?limit=${limit}&offset=${offset}&start_date=${startDate}&end_date=${endDate}&device_id=${deviceId}`
  const data = await DataService.get(url)
  return data
};

const getCurrentWeatherData = async ({ limit, offset, date, deviceId }) => {
  let url = `data/current/date/?limit=${limit}&offset=${offset}&date=${date}&device_id=${deviceId}`
  const data = await DataService.get(url)
  return data.data
};

const getCurrentWeatherGraph = async ({ deviceId }) => {
  let url = `stats/monthly/graph/?device_id=${deviceId}`
  const data = await DataService.get(url)
  return data.data
};

const getWeatherForecastData = async (deviceId) => {
  try {
    const response = await fetch(`${API_URL}?device_id=${deviceId}`, {
      headers: {
        Authorization: AUTH_TOKEN,
        Accept: 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching weather forecast data:', error);
    throw error; // Propagate the error to the caller for better handling
  }
};

export { getWeatherData, getCurrentWeatherData, exportWeatherDataCSV, exportWeatherDataPdf, getWeatherForecastData, getCurrentWeatherGraph };
