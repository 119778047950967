import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import { Link } from "react-router-dom"

// Custom Scrollbar
import SimpleBar from "simplebar-react"

// import images
import weatherImage from "../../assets/images/cloud.png"
import temperatureIcon from "../../assets/images/temperature.svg"
import humidityIcon from "../../assets/images/humidity.svg"
import speedIcon from "../../assets/images/wind-speed.svg"
import rainfallIcon from "../../assets/images/umbrella.svg"
import lightIcon from "../../assets/images/eye.svg"
import locationIcon from "../../assets/images//location.svg"
//i18n
import { withTranslation } from "react-i18next"
import useWeatherDataQuery from "store/WeatherData/useWeatherDataQuery"
import { Line } from "react-chartjs-2"


const Dashboard = props => {

  const mapDataToChart = (rawData, selectedMetric) => {
    // Define colors for the datasets
    const colors = ["#27B111", "#1E90FF", "#FFD700", "#FF6347", "#20B2AA"];
  
    // Select the required metrics and their corresponding units
    const metricsWithUnits = {
      temperature: "°C",
      humidity: "%",
      pressure: "hPa",
      speed: "m/s",
      rainfall: "mm",
    };
  
    // Validate if the selected metric exists in the metricsWithUnits
    if (!metricsWithUnits[selectedMetric]) {
      throw new Error(`Invalid metric selected: ${selectedMetric}`);
    }
  
    // Create the dataset for the selected metric
    const datasets = [
      {
        label: `${selectedMetric.charAt(0).toUpperCase() + selectedMetric.slice(1)} (${metricsWithUnits[selectedMetric]})`,
        fill: false, // Do not fill the area under the line
        lineTension: 0.4, // Smoothness of the line
        backgroundColor: "rgba(0,0,0,0)", // Transparent background for line charts
        borderColor: colors[Object.keys(metricsWithUnits).indexOf(selectedMetric)], // Line color
        borderWidth: 2, // Thickness of the line
        pointBorderColor: colors[Object.keys(metricsWithUnits).indexOf(selectedMetric)], // Border color for points
        pointBackgroundColor: "#fff", // Point background color
        pointBorderWidth: 1, // Point border thickness
        pointHoverRadius: 5, // Point size on hover
        pointHoverBackgroundColor: colors[Object.keys(metricsWithUnits).indexOf(selectedMetric)], // Point background color on hover
        pointHoverBorderColor: "#fff", // Point border color on hover
        pointHoverBorderWidth: 2, // Point border thickness on hover
        pointRadius: 4, // Normal point size
        pointHitRadius: 10, // Hit radius for interaction
        data: rawData.metrics[selectedMetric], // Corresponding data values
      },
    ];

    // Format dates to DD-MM-YYYY
    const formattedDates = rawData?.dates?.map((date) => {
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, "0");
      const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
      const year = d.getFullYear();
      return `${day}-${month}-${year}`;
    });
  
    return {
      labels: formattedDates, // Dates for the x-axis
      datasets: datasets,
    };
  };
  

  const [rainData ,setRainData] = useState({
    labels:[],
    datasets: [
      {
        label: {
          display: false,
        },
        data:[],
      },
    ],
  });
  const [temperatureData ,setTemperatureData] = useState({
    labels:[],
    datasets: [
      {
        label: {
          display: false,
        },
        data:[],
      },
    ],
  });
  const [speedData ,setSpeedData] = useState({
    labels:[],
    datasets: [
      {
        label: {
          display: false,
        },
        data:[],
      },
    ],
  });
  const [humidityData ,setHumidityData] = useState({
    labels:[],
    datasets: [
      {
        label: {
          display: false,
        },
        data:[],
      },
    ],
  });
  const [pressureData ,setPressureData] = useState({
    labels:[],
    datasets: [
      {
        label: {
          display: false,
        },
        data:[],
      },
    ],
  });

  const option = {
    toolbar: {
      show: false,
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const metricLabel = dataset.label; // Get the label (e.g., "Temperature (°C)")
          const currentValue = dataset.data[tooltipItem.index]; // Get the data value
          return `${currentValue} ${metricLabel.match(/\(([^)]+)\)/)[1]}`; // Extract the unit and append it
        },
        title: function (tooltipItem, data) {
          return data.labels[tooltipItem[0].index]; // Return the corresponding label
        },
      },
    },
    // scales: {
    //   xAxes: [
    //     {
    //       barPercentage: 1, // Adjusts the width of each bar (default is 0.9)
    //       categoryPercentage: 0.8, // Adjusts the width of all bars in a group (default is 0.8)
    //     },
    //   ],
    // },
  };
  
  //current date time
  const [date, setDate] = useState(new Date())
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0")
  const mm = String(today.getMonth() + 1).padStart(2, "0") //January is 0!
  const yyyy = today.getFullYear()
  const currentDateInMMDDYYYY = yyyy + "-" + mm + "-" + dd

  const [menu, setMenu] = useState(false);

  const [deviceId, setDeviceId] = useState("DFWSA1015");
  const [filterDate, setFilterDate] = useState(currentDateInMMDDYYYY);

  const toggle = () => {
    setMenu(!menu)
  };

  const [dashboardData, setDashboardData] = useState([
    {
      temperature: 0,
      humidity: 0,
      direction: 0,
      speed: 0,
      rainfall: 0,
      pressure: 0,
      time: new Date(),
      lux: 0,
      id: 0,
    },
  ]);

  const query = {
    deviceId: deviceId,
    limit: 10,
    offset: 0,
  }

  const graphQuery = {
    deviceId: deviceId,
    limit: 10,
    offset: 0,
  }

  const { data: dashboardDatas, isSuccess,refetch:weatherDataRefetch } =useWeatherDataQuery.weatherData(query)
  const { data: graphData, isSuccess: graphSuccess } = useWeatherDataQuery.currentWeatherGraph(graphQuery);

  useEffect(() => {
    if (graphData) {
      setRainData(mapDataToChart(graphData, 'rainfall'));
      setPressureData(mapDataToChart(graphData, 'pressure'));
      setSpeedData(mapDataToChart(graphData, 'speed'));
      setHumidityData(mapDataToChart(graphData, 'humidity'));
      setTemperatureData(mapDataToChart(graphData, 'temperature'));
    } else {
      setRainData([])
      setPressureData([])
      setSpeedData([])
      setHumidityData([])
      setTemperatureData([])
    }
  }, [graphData]);


  const query2 = {
    deviceId: deviceId,
    limit: 10,
    offset: 0,
    date: filterDate,
  }

  const { data: currentDashboardData,refetch:currentWeatherDataRefetch } =useWeatherDataQuery.currentWeatherData(query2)

  React.useEffect(() => {
    if (isSuccess) {
      setDashboardData(dashboardDatas?.results[0])
    }
  }, [isSuccess, dashboardDatas]);

  useEffect(() => {
    const interval = setInterval(() => {
      currentWeatherDataRefetch();
      weatherDataRefetch();
    }, 3600000)
    return () => clearInterval(interval)
  }, []);

  document.title = "Dashboard | Malankara AWS"

  const dateTime = date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  })
  const currentDate = date.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  })

  const roundOff = num => {
    if (!num) return 0
    return num.toFixed(2)
  };

  const convertToKph = speed => {
    if (!speed && speed === 0) return 0
    return speed * 3.6
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center justify-content-between">
              <Col md={9} sm={12}>
                <h6 className="page-title">Dashboard</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Welcome to Malankara AWS Dashboard
                  </li>
                </ol>
              </Col>

              <Col md={3} sm={12}>
                <div className="d-flex justify-content-end flex-column">
                  <div className="text-end">
                    <h5>{currentDate}</h5>
                    <h6>{dateTime} </h6>
                  </div>
                  <div className="d-flex gap-2 justify-content-end">
                    <FormGroup>
                      <Label for="filterDate">Date</Label>
                      <Input
                        value={filterDate}
                        onChange={e => setFilterDate(e.target.value)}
                        type="date"
                        name="filterDate"
                        id="filterDate"
                      ></Input>
                    </FormGroup>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <Row>
            <Col xl={12}>
              <Card style={{ borderRadius: "24px," }}>
                <CardBody>
                  <Row>
                    <Col md={3}>
                      <Card
                        style={{ background: "#E3FDF2", borderRadius: "24px" }}
                      >
                        <CardBody>
                          <h5 className="text-black mb-3">Temperature</h5>
                          <h6 className="text-black">
                            High :{" "}
                            <span className="text-success">
                              {roundOff(
                                currentDashboardData?.data?.temperature?.high ||
                                0
                              )}{" "}
                              (°C)
                            </span>{" "}
                          </h6>
                          <h6 className="text-black">
                            Low :{" "}
                            <span className="text-danger">
                              {roundOff(
                                currentDashboardData?.data?.temperature?.low ||
                                0
                              )}{" "}
                              (°C)
                            </span>{" "}
                          </h6>
                        </CardBody>
                      </Card>
                    </Col>
                    {/* <Col md={3}>
                      <Card
                        style={{ background: "#E3FDF2", borderRadius: "24px" }}
                      >
                        <CardBody>
                          <h5 className="text-black mb-3">Humidity</h5>
                          <h6 className="text-black">
                            High :{" "}
                            <span className="text-success">
                              {roundOff(
                                currentDashboardData?.data?.humidity?.high || 0
                              )}{" "}
                              (%)
                            </span>{" "}
                          </h6>
                          <h6 className="text-black">
                            Low :{" "}
                            <span className="text-danger">
                              {roundOff(
                                currentDashboardData?.data?.humidity?.low || 0
                              )}{" "}
                              (%)
                            </span>{" "}
                          </h6>
                        </CardBody>
                      </Card>
                    </Col> */}
                    <Col md={3}>
                      <Card
                        style={{
                          background: "#E3FDF2",
                          borderRadius: "24px",
                          paddingBottom: "10px",
                        }}
                      >
                        <CardBody>
                          <h5 className="text-black mb-3">Wind Speed</h5>
                          <h6 className="text-black">
                            High :{" "}
                            <span className="text-success">
                              {roundOff(
                                convertToKph(currentDashboardData?.data?.speed_max?.high) || 0
                              )}{" "}
                              (kph)
                            </span>{" "}
                          </h6>
                          <h6 className="text-black">
                            Low :{" "}
                            <span className="text-danger">
                              {roundOff(
                                convertToKph(currentDashboardData?.data?.speed_max?.low) || 0
                              )}{" "}
                              (kph)
                            </span>{" "}
                          </h6>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={3}>
                      <Card
                        style={{
                          background: "#E3FDF2",
                          borderRadius: "24px",
                          paddingBottom: "10px",
                        }}
                      >
                        <CardBody>
                          <h5 className="text-black mb-3">Rain Fall</h5>
                          <h6 className="text-black">
                            Daily Rain :{" "}
                            <span className="text-success">
                              {roundOff(
                                currentDashboardData?.data?.rainfall_24?.high || 0
                              )}{" "}
                              (mm)
                            </span>{" "}
                          </h6>
                          
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Card style={{ borderRadius: "24px," }}>
                <CardBody className="ps-0 pb-0">
                  <div
                    className="d-flex justify-content-start align-items-center gap-2 p-2 px-3 ms-3"
                    style={{
                      width: "130px",
                      backgroundColor: "#E3FDF2",
                      borderRadius: "24px",
                    }}
                  >
                    <img src={locationIcon} alt="location" className="" />
                    <h6 className="mt-1">Idukki</h6>
                  </div>
                  <div className="ms-4">
                    <h3 className="card-title mt-2">Weather</h3>
                    <p className="text-muted mb-4">Now</p>
                  </div>
                  <Row>
                    <Col lg={6} style={{ position: "relative" }}>
                      <div style={{ position: "absolute", bottom: 0, left: 0 }}>
                        <img src={weatherImage} alt="weather" className="" />
                      </div>
                      {/* <div
                        style={{ position: "absolute", top: "0", right: "20%" }}
                      >
                        <img src={climateIcon} alt="weather" className="" />
                      </div> */}
                    </Col>
                    <Col lg={6} className="mb-5">
                      <Row>
                        <Col md={4}>
                          <div className="text-start">
                            <img
                              width={24}
                              height={24}
                              src={temperatureIcon}
                              alt="temperature"
                            />
                            <h5 className="mt-2"> Temperature</h5>
                            <p className="text-muted mb-4">
                              {roundOff(dashboardData?.temperature || 0)} (°C)
                            </p>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="text-start">
                            <img
                              width={24}
                              height={24}
                              src={humidityIcon}
                              alt="humidity"
                            />
                            <h5 className="mt-2"> Humidity</h5>
                            <p className="text-muted mb-4">
                              {roundOff(dashboardData?.humidity || 0)} (%)
                            </p>
                          </div>
                        </Col>
                        {/* {<Col md={4}>
                          <div className="text-start">
                            <img
                              width={24}
                              height={24}
                              src={windIcon}
                              alt="direction"
                            />
                            <h5 className="mt-2">Wind Direction</h5>
                            <p className="text-muted mb-4">
                              {getDirection(dashboardData?.direction || 0)}
                            </p>
                          </div>
                        </Col>} */}
                        <Col md={4}>
                          <div className="text-start">
                            <img
                              width={24}
                              height={24}
                              src={speedIcon}
                              alt="speedIcon"
                            />
                            <h5 className="mt-2"> Wind Speed</h5>
                            <p className="text-muted mb-4">
                              {roundOff(convertToKph(dashboardData?.speed) || 0)} (kph)
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col md={4}>
                          <div className="text-start">
                            <img
                              width={24}
                              height={24}
                              src={rainfallIcon}
                              alt="rainfall"
                            />
                            <h5 className="mt-2"> Rain Fall</h5>
                            <p className="text-muted mb-4">
                              {dashboardData?.rainfall || 0}
                            </p>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="text-start">
                            <img
                              width={24}
                              height={24}
                              src={lightIcon}
                              alt="light"
                            />
                            <h5 className="mt-2">Pressure</h5>
                            <p className="text-muted mb-4">
                              {roundOff(dashboardData?.pressure || 0)} (hPa)
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Card style={{ borderRadius: "24px," }}>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <Card
                        style={{ background: "#E3FDF2", borderRadius: "24px" }}
                      >
                        <CardBody>
                          <Line width={600} height={245} data={rainData} options={option} />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6}>
                      <Card
                        style={{ background: "#E3FDF2", borderRadius: "24px" }}
                      >
                        <CardBody>
                          <Line width={600} height={245} data={temperatureData} options={option} />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6}>
                      <Card
                        style={{ background: "#E3FDF2", borderRadius: "24px" }}
                      >
                        <CardBody>
                          <Line width={600} height={245} data={speedData} options={option} />
                        </CardBody>
                      </Card>
                    </Col>

                    <Col md={6}>
                      <Card
                        style={{ background: "#E3FDF2", borderRadius: "24px" }}
                      >
                        <CardBody>
                          <Line width={600} height={245} data={humidityData} options={option} />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Dashboard)
